import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import AuthText from '../../components/AuthText';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import HomeBlocks from '../../components/HomeBlocks';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import SigninForm from '../../components/SigninForm/SigninForm';
import { Wrapper } from '../../components/Ui';
import ViewerSlider from '../../components/ViewerSlider/ViewerSlider';
import customAxiosNm from '../../config/axios-refresh-token';
import useQuery from '../../hooks/useQuery';
import { appQuery } from '../../store/actions/app';
import { showAuthForm } from '../../store/actions/auth';
import { respondTo } from '../../theme/mixin';

const AuthTemplate = props => {
  // const [isLogin, setIsLogin] = useState(false);

  const { from } = props.location.state || { from: '/' };
  const isLogin = useSelector(state => state.auth.show_auth_form);
  const dispatch = useDispatch();
  const { auth, token } = useParams();
  const query = useQuery();
  const queryApp = query.get('app');
  const open = query.get('open');
  // const token = query.get('token');
  const appQueryParam = useSelector(state => state.app?.appQuery);
  const visible = useSelector(state => state.app?.confg?.visible);
  const isLogged = useSelector(state => state.auth.token);
  const history = useHistory();
  const [registerData, setRegisterData] = useState(null);
  const individual_agreement_accepted = useSelector(
    state => state.user?.user?.userinfo?.individual_agreement_accepted,
  );
  const individual_agreement = useSelector(state => state.app.config?.individual_agreement);

  useEffect(() => {
    if (individual_agreement && !individual_agreement_accepted && isLogged) {
      history.push('/single-agreement');
    }
  }, [individual_agreement_accepted, history, isLogged, individual_agreement]);

  useEffect(() => {
    history.location.pathname === '/auth/register' && dispatch(showAuthForm(true));
    if (!token) return;
    customAxiosNm
      .get(`/auth/externaltokendecode?token=${token}`)
      .then(response => {
        setRegisterData(response.data);
        dispatch(showAuthForm(true));
      })
      .catch(error => {});
  }, []);

  useEffect(() => {
    const localQueryApp = localStorage.getItem('queryApp');

    if (queryApp || localQueryApp) {
      if (queryApp) {
        dispatch(appQuery(queryApp));
      } else {
        dispatch(appQuery(localQueryApp));
      }
      localStorage.setItem('queryApp', true);
    }
  }, [queryApp]);
  useEffect(() => {
    visible && history.location.pathname === '/auth/register' && history.push('/auth/login');
  }, [visible, history.location.pathname]);
  return (
    <CustomWrapper>
      {!Boolean(appQueryParam) && <Header />}
      <SidesWrapper>
        <ViewerSlider />
        <AuthText />
        <HomeBlocks />

        {Boolean(isLogin || open === 'true') && (
          <FormContainer visible={visible && history.location.pathname === '/auth/register'}>
            <Route path='/auth/login' exact>
              <SigninForm whereToGo={from} />
            </Route>

            <Route path={token ? '/auth/register/:token*' : '/auth/register'} exact>
              <RegisterForm data={registerData} />
            </Route>

            <Route path='/auth/recovery-password' exact>
              <RecoveryPasswordForm />
            </Route>
            {props.children}
          </FormContainer>
        )}
        {!Boolean(appQueryParam) && <Footer />}
      </SidesWrapper>
    </CustomWrapper>
  );
};

const CustomWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const SidesWrapper = styled.div`
  display: block;
  margin-bottom: 50px;
  margin-left: 0;
  margin-right: 0;
  flex-grow: 1;
  //margin-top: 103px;
  position: relative;
  ${respondTo.sm`
    margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px;
  `}
`;

const FormContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bg_box};
  flex: 0 0 100%;
  padding: 30px 15px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999999999;
  position: absolute;
  right: 0;
  top: -13px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colore_bordo_box};
  ${respondTo.sm`
    flex: 0 0 400px;
    width: 550px;
  `};
`;

AuthTemplate.propTypes = {
  logo: PropTypes.string,
  titlepage: PropTypes.string,
  children: PropTypes.any,
};

export default withRouter(AuthTemplate);
